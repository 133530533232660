import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PartnershipsData } from '../../../data/partners-db';
import { PartnersData } from '../../../data/carousal-db';

export default function OurPartners() {
  return (
    <section className="partnership-header pb-5 pt-3">
      <Container>
        <Row>
          <h2 className="text-center pb-4">
            {PartnershipsData[0].heading}
            {' '}
            <span className="infenox-text-highlight partner-line" />
          </h2>

          {PartnersData[0].data.map(({ id, image, url }) => (
            <Col className="col-md-3 partner-item" key={id}><a href={url} target="_blank" rel="noreferrer"><img src={image} alt="Our Partners" /></a></Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
