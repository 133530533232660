import awsLogo from '../images/awsLogo.png';
import optimizelyLogo from '../images/optimizelyLogo.png';
import netsuiteLogo from '../images/netsuiteLogo.png';
import sanaLogo from '../images/sanaLogo.png';
import microsoftLogo from '../images/microsoftLogo.png';
import oracleLogo from '../images/oracleLogo.png';
import deloitteLogo from '../images/deloitteLogo.png';
import bdoLogo from '../images/bdoLogo.png';
import teboLogo from '../images/teboLogo.png';
import kernLogo from '../images/kernLogo.png';
import babcockLogo from '../images/babcockLogo.png';
import brillioLogo from '../images/brillioLogo.png';
import andrewpellerLogo from '../images/andrewpellerLogo.png';

export const PartnersData = [
  {
    id: 1,
    heading: 'Strategic Partnership',
    data: [
      {
        id: 1,
        image: awsLogo,
        url: 'https://aws.amazon.com',
      },
      {
        id: 2,
        image: optimizelyLogo,
        url: 'https://optimizely.com',
      },
      {
        id: 3,
        image: netsuiteLogo,
      },
      {
        id: 4,
        image: sanaLogo,
        url: 'https://sana-commerce.com',
      },
      {
        id: 5,
        image: microsoftLogo,
        url: 'https://microsoft.com',
      },
    ],
  },
];

export const ClientData = [
  {
    id: 1,
    heading: 'Our Esteemed Clients',
    data: [
      {
        id: 1,
        image: oracleLogo,
        url: 'https://oracle.com',
      },
      {
        id: 2,
        image: deloitteLogo,
        url: 'https://deloitte.com',
      },
      {
        id: 3,
        image: bdoLogo,
        url: 'https://bdo.com',
      },
      {
        id: 4,
        image: teboLogo,
        url: 'https://tebo-group.com',
      },
      {
        id: 5,
        image: kernLogo,
        url: 'https://kernbsg.com',
      },
      {
        id: 6,
        image: babcockLogo,
        url: 'https://babcockinternational.com',
      },
      {
        id: 7,
        image: brillioLogo,
        url: 'https://brillio.com',
      },
      {
        id: 8,
        image: andrewpellerLogo,
        url: 'http://andrewpeller.com',
      },
    ],
  },
];
